import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProductDefinitionJson, ScheduleContext, WizardContexts, WizardTypes } from 'app/shared/models';
import { FollowupWizardContext, SurveyWizardContext, isFolloupWizardContext, isSurveyWizardContext } from 'app/shared/models/product-wizard.model';
import { ProductService } from 'app/shared/services';
import * as ProductWizardActions from 'app/state/actions/product-wizard.actions';
import { AppState } from 'app/state/app.state';
import { initialState as DefaultSurveyWizardState, getNextBusinessDay, getReminderEmails } from 'app/state/reducers/survey-wizard.reducer';
import { from } from 'rxjs';
import { filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as SurveyWizardActions from '../actions/survey-wizard.actions';
import { initialState as DefaultFollowupWizardState } from '../reducers/followup-wizard.reducer';

@Injectable()
export class ProductWizardEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private productService: ProductService,
    private snackBar: MatSnackBar,
    private translations: TranslocoService,
  ) {
  }
  createFromSurvey$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.CreateFromSurvey>('[SURVEY_WIZARD] CREATE_FROM_SURVEY'),
    switchMap((action) =>
      this.productService.getById(action.payload).pipe(
        switchMap(product => {
          const stateObj = JSON.parse(product.state) as SurveyWizardContext;
          const definitionObject = JSON.parse(product.companyProductDefinition.definition) as ProductDefinitionJson;
          const productDefinition = { ...product.companyProductDefinition, definitionObject: definitionObject }
          const newStateObj: SurveyWizardContext =
          {
            ...DefaultSurveyWizardState,
            productDefinition: productDefinition,
            modules: stateObj.modules,
            moduleId: stateObj.moduleId
          };
          return from([
            new ProductWizardActions.CreateFromSurveySuccess(newStateObj),
            new SurveyWizardActions.GetIndexInfo()])
        })
      ))
  ));

  loadDraft$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.LoadDraft>(ProductWizardActions.Type.LOAD_DRAFT),
    withLatestFrom(this.store.select(s => s.surveyWizardContext)),
    switchMap(([action, surveyWizardContext]) => {
      const product$ = typeof action.payload === 'number'
        // referer is url
        ? this.productService.getById(action.payload)
        // referer is product selector
        : from([action.payload]);

      return product$.pipe(switchMap(product => {
        let context: WizardContexts;
        if (product === null || product.state === null) {
          if (!surveyWizardContext.productId) {
            // We end up here if we don't have an actual draft, but just a selected product definition
            // so we need to start the zelection flow all over. That will give us a new product Id
            return from([new ProductWizardActions.SelectProductDefinition(product.companyProductDefinition, WizardTypes.survey)]);
          }
        } else {

          context = JSON.parse(product.state, this.reviver) as WizardContexts;
          context.currentVersion = product.currentVersion;
          if (!context.productDefinition?.definitionObject) { // parse old drafts lacking definitionObject
            context.productDefinition.definitionObject = JSON.parse(context.productDefinition.definition);
          }
          if (context.wizardType === WizardTypes.followup) {
            if (product.actions && product.actions.length >= context.actions.length) {
              context.actions = product.actions;
            }
          } else if (context.wizardType === WizardTypes.survey) {
            this.setupSurveyDates(context.schedule);
          }
        }

        if (context?.productId === null) {
          context.productId = typeof action.payload === 'number' ? action.payload : action.payload.id;
        }
        if (action.forceStep > -1) {
          context.activeStep = action.forceStep;
        }

        // Fix for old drafts /w no name
        if (isSurveyWizardContext(context) && context?.name == null) {
          context.name = context.productDefinition?.name;
        }

        if (isSurveyWizardContext(context)) {
          context.wasDeactivated = action.wasDeactivated;
          // get new user count (setGroups), things in organization might have change, people might have been added or removed
          //  TODO! also; for now we need to save draft after loading it in order for rules to be reapplied

          return from([
            new ProductWizardActions.SetWizardState(context),
            new SurveyWizardActions.SetGroups(context.participants.groups),
            new ProductWizardActions.SetChanged({ changed: false }),
            new ProductWizardActions.SaveDraft(context)
          ]);
        } else {
          return from([new ProductWizardActions.SetWizardState(context),
          new ProductWizardActions.SetChanged({ changed: false })]);
        }

      }));
    })
  ));

  createDraft$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.CreateDraft>(ProductWizardActions.Type.CREATE_DRAFT),
    withLatestFrom(this.store.select(s => s.companyContext), this.store.select(s => s.surveyWizardContext)),
    switchMap(([action, context, surveyWizardContext]) => {
      let defaultWizardState: WizardContexts;
      let initialDraftState: string = null;

      if (isSurveyWizardContext(action.payload)) {

        const initialState = surveyWizardContext;
        defaultWizardState = {
          ...initialState,
          name: '',
          index: {
            indexValidation: [...initialState.index.indexValidation],
            indexes: initialState.index.indexes ? [...initialState.index.indexes] : null,
            indexInfo: [...action.payload.index.indexInfo]
          }
        };
      }

      if (isFolloupWizardContext(action.payload)) {
        defaultWizardState = this.getInitialState(action.payload.wizardType) as FollowupWizardContext;
        initialDraftState = defaultWizardState != null ? JSON.stringify(defaultWizardState) : null;
      }

      const state: WizardContexts = JSON.parse(JSON.stringify(defaultWizardState), this.reviver);
      state.productDefinition = action.payload.productDefinition;

      return this.productService.create(context.id, action.payload.productDefinition.id, initialDraftState)
        .pipe(
          switchMap(product => {
            return from([
              new ProductWizardActions.SaveDraft({ ...state, productId: product.id, currentVersion: product.currentVersion }),
              new ProductWizardActions.SetWizardState({ ...state, productId: product.id, currentVersion: product.currentVersion }),
            ])
          }
          )
        );
    })
  ));

  draftSaved$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.DraftSaved>(ProductWizardActions.Type.DRAFT_SAVED),
    filter(action => action.showMessage),
    tap(() => this.translations.selectTranslate('INSIGHT.GLOBAL.UPDATED')
      .pipe(take(1))
      .subscribe((t: string) => {
        this.snackIt(t + '!', null);
      })),
    switchMap(_ => from([]))
  ));

  selectProductDefinition$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SelectProductDefinition>(ProductWizardActions.Type.SELECT_PRODUCT_DEFINITION),
    switchMap((action) => from([
      new ProductWizardActions.SelectProductDefinitionSuccess(action.payload, action.wizardType)
    ]))
  ));
  setChange$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SetChanged>(ProductWizardActions.Type.SET_CHANGED),
    switchMap(action => {
      return from([new ProductWizardActions.SetChangedSuccess({ changed: action.payload.changed, action: action.payload.action })]);
    })
  ));

  actionAfterChanged$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SetChangedSuccess>(ProductWizardActions.Type.SET_CHANGED_SUCCESS),
    switchMap(action => {
      return action.payload.action ? from([action.payload.action]) : from([]);
    })
  ))

  private snackIt(title: string, desc: string) {
    const popup = this.snackBar.open(title, desc,
      { duration: 3000, politeness: 'polite', panelClass: 'polite' });
    popup.onAction().subscribe(() => {
      popup.dismiss();
    });
  }
  reviver(_, value: any) {
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (typeof value === 'string' && dateFormat.test(value)) {
      return new Date(value);
    }
    return value;
  }

  private getInitialState(wizardType: WizardTypes): WizardContexts {
    switch (wizardType) {
      case WizardTypes.survey:
        return DefaultSurveyWizardState;
      case WizardTypes.followup:
        return DefaultFollowupWizardState;
    }

  }
  private setupSurveyDates(scheduleContext: ScheduleContext): void {
    const todaysDate = new Date();
    if (this.isValidDate(scheduleContext.startDate)) {
      const startDate = new Date(scheduleContext.startDate);
      if (startDate.getTime() < todaysDate.getTime()) {
        scheduleContext.startDate = new Date(
          todaysDate.getFullYear(),
          todaysDate.getMonth(),
          todaysDate.getDate(),
          startDate.getHours(),
          startDate.getMinutes(),
          0);
      }
    } else {
      scheduleContext.startDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate());
    }
    const oldDraft = scheduleContext.startDate < todaysDate || scheduleContext.startDate > scheduleContext.endDate;
    const stillOkEndDate = scheduleContext.endDate && scheduleContext.endDate > todaysDate;
    if (oldDraft && !stillOkEndDate) {
      scheduleContext.endDate = new Date(
        todaysDate.getFullYear(),
        todaysDate.getMonth(),
        todaysDate.getDate() + 14, 23, 59, 0);
    }
    if (!scheduleContext.reportDate || scheduleContext.reportDate < scheduleContext.endDate) {
      scheduleContext.reportDate = getNextBusinessDay(new Date(
        scheduleContext.endDate.getFullYear(),
        scheduleContext.endDate.getMonth(),
        scheduleContext.endDate.getDate(),
        9, 0, 0));
    }
    if (scheduleContext.reminderEmails.some(r => r.date < scheduleContext.startDate)) {
      scheduleContext.reminderEmails = getReminderEmails(scheduleContext.startDate, scheduleContext.endDate, scheduleContext.reminderEmails[0].emailId);
    }
  }
  private isValidDate(d) {
    if (Object.prototype.toString.call(d) === '[object Date]') {
      if (isNaN(d.getTime())) {  // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
